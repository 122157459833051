import { useState } from "react";
import AddSum from "./AddSum";

const Sport = ({ data, addValue }) => {
  const [addSum, setAddSum] = useState("adding"); // for css to show value changed

  if (data === undefined) return null;

  const timeout = async () => {
    return new Promise((resolve) => {
      setTimeout(resolve, 100);
    });
  };

  const handleAddClick = async (adding) => {
    const newValue = data.currValue + Number(adding);
    setAddSum("adding-clicked");
    addValue({ sport: data.name_fi, amount: newValue });
    await timeout();
    setAddSum("adding");
  };

  return (
    <div className="sport">
      <h2 className="header2">
        {data.name_fi.charAt(0).toUpperCase() + data.name_fi.slice(1)}
      </h2>
      <h4>Tänä vuonna</h4>
      <p className="amount-year">{data.this_year}</p>
      <h4>Kuluva viikko</h4>
      <p className="amount-week">{data.this_week}</p>
      <h4>Tänään</h4>
      <p className="amount-today">{data.today}</p>
      <hr></hr>
      <div className="past-workouts">
        <h4>Viimeiset 12 kk</h4>
        <p className="last-12-months">{data.last_12_months}</p>
        <h4>
          Tällä viikolla
          <br />
          poistuvat
        </h4>
        <p className="last-year-week">{data.weeks_removal}</p>
      </div>
      <hr></hr>
      <AddSum cssName={addSum} value={data.currValue} />
      <button
        className="button-add"
        onClick={(e) => handleAddClick(e.target.innerHTML)}>
        +10
      </button>
      <button
        className="button-add"
        onClick={(e) => handleAddClick(e.target.innerHTML)}>
        +5
      </button>
      <button
        className="button-add"
        onClick={(e) => handleAddClick(e.target.innerHTML)}>
        +1
      </button>
      <button
        className="button-add"
        onClick={(e) => handleAddClick(e.target.innerHTML)}>
        -1
      </button>
    </div>
  );
};

export default Sport;
