import { Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";

import Sports from "./Sports";
import Login from "./login/Login";
import CreateUser from "./components/CreateUser";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);

  return (
    <Routes>
      <Route path="/create-user" element={<CreateUser />} />
      <Route
        path="/login"
        element={<Login setIsLoggedIn={setIsLoggedIn} redirect={"/"} />}
      />
      <Route path="/" element={<Sports isLoggedIn={isLoggedIn} />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
