import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sport from "./components/Sport";
import "./index.css";

const Sports = () => {
  const [sportsData, setSportsData] = useState();
  const [values, setValues] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const response = await fetch("/sports/backend/get-all");
      if (response.status !== 200) {
        navigate("/login");
      } else {
        const resjson = await response.json();
        for (let i = 0; i < resjson.length; i++) {
          resjson[i] = { ...resjson[i], currValue: 0 };
        }
        setSportsData(resjson);
      }
    };

    getData();
  }, []);

  const addValue = (adding) => {
    setSportsData((prev) =>
      prev.map((s) =>
        s.name_fi === adding.sport ? { ...s, currValue: adding.amount } : s
      )
    );
    const temp = values.filter((s) => s.sport === adding.sport);
    if (temp.length === 0) {
      setValues((prevValues) => [...prevValues, adding]);
    } else {
      setValues((prevValues) =>
        prevValues.map((s) =>
          s.sport === adding.sport ? { ...s, amount: adding.amount } : s
        )
      );
    }
  };

  const sendClicked = async () => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };
    const result = await fetch("/sports/backend/add", options);
    if (!result.ok) {
      navigate("/login");
      return;
    }
    const newData = await result.json();
    for (let i = 0; i < newData.length; i++) {
      newData[i] = { ...newData[i], currValue: 0 };
    }
    setValues([]);
    setSportsData(newData);
  };

  const logout = async () => {
    await fetch("/sports/backend/logout");

    return navigate("/login");
  };

  return (
    <div className="sports-body">
      <button id="logout" onClick={logout}>
        Logout
      </button>
      <button id="send-data" onClick={sendClicked}>
        Lähetä
      </button>

      <div className="workouts">
        {sportsData &&
          sportsData.map((sport, index) => (
            <Sport key={index} data={sport} addValue={addValue} />
          ))}
      </div>
      <div id="answer-container"></div>
    </div>
  );
};

export default Sports;
