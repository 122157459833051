import { useState } from "react";
import "../login/login.css";
import internal from "../config";

const CreateUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(undefined);

  const updateText = (e) => {
    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "password2":
        setPassword2(e.target.value);
        break;
    }
  };

  const submitCreds = async () => {
    const body = { username: username, email: email, password: password };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    const response = await fetch(
      internal.APP + "/backend/create-user",
      options
    );
    //const res = await response.json();
    console.log("res:", response.ok);
    if (!response.ok) {
      try {
        const res = await response.json();
        setError(res?.message);
      } catch (err) {
        setError("Unknown error");
      }
    }
    return;
  };

  return (
    <div className="login-wrapper">
      <h2>Create user</h2>
      <p>Please fill in your credentials.</p>
      <div className="form">
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            className="form-control"
            value={username}
            onChange={updateText}
          />
          <span className="help-block"></span>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={email}
            onChange={updateText}
          />
          <span className="help-block"></span>
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            value={password}
            onChange={updateText}
          />
          <span className="help-block"></span>
        </div>
        <div className="form-group">
          <label>Password again</label>
          <input
            type="password"
            name="password2"
            className="form-control"
            value={password2}
            onChange={updateText}
          />
          <span className="help-block"></span>
        </div>
        <div className="form-group">
          <button className="btn btn-primary" onClick={submitCreds}>
            Create user
          </button>
        </div>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default CreateUser;
