import { useRef, useState } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";

const Login = ({ setIsLoggedIn, redirect }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState("");
  const inputReference = useRef(null);
  const navigate = useNavigate();

  const updateText = (e) => {
    setError("");
    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };

  const keyPressed = (e) => {
    if (e.key === "Enter") {
      submitCreds();
    } else if (e.key === "Escape") {
      setUsername("");
      setPassword("");
      inputReference.current.focus();
    }
  };

  const submitCreds = async () => {
    const body = { username: username, password: password, remember: remember };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    const response = await fetch("/sports/backend/login", options);
    const res = await response.json();
    if (res.code === 200) {
      setIsLoggedIn(true);
      navigate(redirect);
    } else {
      setError(res.error);
      inputReference.current.focus();
    }
    return;
  };

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <h2 className="login-h2">Login</h2>
        <p>Please fill in your credentials to login.</p>
        <div className="form">
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              name="username"
              className="form-control"
              value={username}
              onChange={updateText}
              onKeyDown={keyPressed}
              ref={inputReference}
            />
            <span className="help-block"></span>
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              value={password}
              onChange={updateText}
              onKeyDown={keyPressed}
            />
            <span className="help-block">{error}</span>
          </div>
          <div className="remember">
            <input
              type="checkbox"
              id="remember"
              name="checkbox_remember"
              value={remember}
              onClick={() => setRemember(!remember)}
            />
            <label htmlFor="remember">Remember me!</label>
          </div>
          <div className="form-group">
            <button className="btn btn-primary" onClick={submitCreds}>
              Login
            </button>
          </div>
          <p>
            Don&apos;t have an account?
            <br />
            <Link to="/create-user">Sign up now</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
